import api from '@/services/http'
import { constructParams, extractSignal } from '@/helpers/api'

export async function getPeople(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    [
      'limit',
      'offset',
      'name',
      'role',
      'module',
      'showInactive',
      'directManagerIDs',
      'occupationIDs',
    ],
    data
  )

  return (await api()).get('/management/people', {
    params: params,
    ...signal,
  })
}
