import { AbilityBuilder, createMongoAbility } from '@casl/ability'

export const ability = createMongoAbility()

export function definePermissions(permissions = []) {
  const isArray = Array.isArray(permissions)
  const isStringArray = permissions.every(key => typeof key === 'string')

  if (!isArray || !isStringArray) {
    permissions = []
  }

  const { can, rules } = new AbilityBuilder(createMongoAbility)
  permissions.forEach(key => can('access', key))
  ability.update(rules)
}

export function definePermissionToggle(permission, value = null) {
  const { can, cannot, rules } = new AbilityBuilder(createMongoAbility)
  rules.push(...ability.rules)

  if (typeof value !== 'boolean') {
    value = !can('access', permission)
  }

  const rule = rules.find(
    el => el.action === 'access' && el.subject === permission
  )
  if (rule) {
    rule.inverted = !value
    ability.update(rules)

    return
  }

  if (value) {
    can('access', permission)
  } else {
    cannot('access', permission)
  }

  ability.update(rules)
}

export { CanActionOnNotification } from './entities'
