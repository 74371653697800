import * as _permissions from '@/helpers/ability/permissions'

export const MainPageRouterName = 'NotificationsPage'

const Views = {
  Login: () =>
    import(/* webpackChunkName: "LoginPage" */ '@/views/Login/Login.vue'),
  NotFound: () =>
    import(
      /* webpackChunkName: "NotFoundPage" */ '@/views/NotFound/NotFound.vue'
    ),
  MainLayout: () =>
    import(
      /* webpackChunkName: "BoxBasedLayout" */ '@/views/BoxBasedLayout/BoxBasedLayout.vue'
    ),
  Header: () => import('@/views/ReportHeader/ReportHeader.vue'),
}

const Pages = {
  NotificationsList: () =>
    import('@/pages/NotificationsList/NotificationsList.vue'),
  ShippingCenter: () => import('@/pages/ShippingCenter/ShippingCenter.vue'),
}

export const moduleRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Views.Login,
  },
  {
    path: '*',
    name: 'NotFound',
    component: Views.NotFound,
  },
  {
    path: '/',
    name: MainPageRouterName,
    component: Views.MainLayout,
    redirect: { name: 'NotificationsListPage' },
    meta: {
      title: 'Notificações',
      requiresAuth: true,
      requiresPermission: _permissions.profile,
    },
    children: [
      {
        path: 'list',
        name: 'NotificationsListPage',
        components: {
          default: Pages.NotificationsList,
          header: Views.Header,
        },
        meta: {
          title: 'Notificações',
          requiresPermission: _permissions.profile,
        },
      },
      {
        path: 'shipping-center',
        name: 'ShippingCenterPage',
        components: {
          default: Pages.ShippingCenter,
          header: Views.Header,
        },
        meta: {
          title: 'Central de envios',
          requiresPermission: _permissions.profile,
        },
      },
    ],
  },
]
