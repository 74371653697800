<template>
  <LateralMenu v-if="_showLateralMenu">
    <template #default="{ isMini }">
      <ManageFilters
        v-if="_showManageFilters"
        class="menu-item"
        :mini="isMini"
      ></ManageFilters>
    </template>
  </LateralMenu>
</template>

<script>
import ManageFilters from './Partials/ManageFilters/ManageFilters.vue'

export default {
  name: 'LateralMenuView',
  components: {
    ManageFilters,
  },
  computed: {
    _currentContext() {
      const routeName = this.$route.name
      const contexts = {
        ClimatePage: 'climate',
        ComparisonPage: 'comparison',
        OpinionsPage: 'opinions',
        ParticipationsPage: 'participations',
        ManagePage: 'manage',
      }
      return contexts[routeName] || ''
    },
    _surveyPages() {
      return {
        SurveyInit: 'survey',
        SurveySteps: 'survey',
        SurveyFeedback: 'survey',
        SurveyFinish: 'survey',
        SurveyForbidden: 'survey',
      }
    },
    _showLateralMenu() {
      const allowedPages = ['manage']
      return allowedPages.includes(this._currentContext)
    },
    _showManageFilters() {
      const allowedContexts = ['manage']
      return allowedContexts.includes(this._currentContext)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
