import * as notifications from './permissions/notifications'
import * as performance from './permissions/performance'
import * as libs from './libs'

export const NestedGeneralPermissions = Object.keys({
  ...performance,
  ...notifications,
}).reduce((acc, key) => {
  const nestedPermissions = libs.splitUnderlineToNestedObject(key)
  return libs.mergeObjectsRecursively(acc, nestedPermissions)
}, {})

const transformIng = i => {
  if (typeof i === 'string') return i
  else {
    delete i['_key']
    return Object.keys(i)
      .map(key => i[key])
      .join('')
  }
}

/**
 * "If the user has the permission to perform the action on the notification, return true, otherwise return false."
 *
 * The function takes four arguments:
 *
 * * `typeAction`: The action the user wants to perform.
 * * `entity`: The notification the user wants to perform the action on.
 * * `WhoImUser`: The user who wants to perform the action.
 * * `can`: A function that checks if the user has the permission to perform the
 * action
 * @param [typeAction] - The type of action you want to check.
 * @param [entity] - the notification you want to check permissions for
 * @param [can] - the function that checks if the user has the permission
 * @returns A function that takes in 4 parameters and returns a boolean.
 */

export function CanActionOnNotification(
  typeAction,
  entity,
  can,
  tryAllDeep = false
) {
  const nested = NestedGeneralPermissions['notifications']
  if (!nested) return

  if (typeof typeAction !== 'string') return
  let typeSubAction
  if (typeAction.includes('_')) {
    typeSubAction = typeAction.split('_')[1]
    typeAction = typeAction.split('_')[0]
  }

  // if the entity has Permissions
  const permissions =
    'permissions' in entity && Array.isArray(entity.permissions)
      ? entity.permissions
      : []

  const ePermissions = []
  if (permissions.length > 0) {
    ePermissions.push(...permissions.map(i => transformIng(i)))
  }

  const nestedDeepKeys = {
    Action: notifications[nested[typeAction]?._key],
    SubAction: typeSubAction
      ? notifications[nested[typeAction][typeSubAction]?._key]
      : null,
  }

  // check if the user has the permission or if the entity has the permission
  const PermissionsOrCanByKey = key => {
    if (ePermissions.includes(key)) return true
    return can('access', key)
  }

  // permissions checking start!
  const keys = Object.keys(nestedDeepKeys)
  for (const key of keys) {
    if (
      (!tryAllDeep && typeSubAction && !key.includes('SubAction')) ||
      (!tryAllDeep && !typeSubAction && key.includes('SubAction'))
    )
      continue

    if (nestedDeepKeys[key]) {
      if (PermissionsOrCanByKey(nestedDeepKeys[key])) return true
    }
  }

  return false
}
